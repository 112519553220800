import { useEffect, useState } from 'react';

/**
 * If className is provided, then it acts the opposite
 */
export default function HeaderScrollListener({ className }) {
	const [listener, setListener] = useState(null);
	const [header, setHeader] = useState(null);

	useEffect(() => {
		if (!document) return;

		function handlePageScroll() {
			if (!header) return;
			const scrolled = document.scrollingElement.scrollTop;
			if (scrolled >= 80) {
				if (className) header.classList.remove(className);
				header.classList.add('scrolled');
			} else {
				if (className) header.classList.add(className);
				header.classList.remove('scrolled');
			}
		}

		if (!listener) {
			setListener(handlePageScroll);
			document.addEventListener('scroll', handlePageScroll);
			handlePageScroll();
		}

		if (!header) {
			const headers = document.getElementsByClassName('ant-layout-header');
			if (headers && headers.length > 0) setHeader(headers[0]);
		} else if (className) {
			header.classList.add(className);
		}
		return () => {
			document.removeEventListener('scroll', handlePageScroll);
		};
	}, [listener, header]);

	return null;
}
