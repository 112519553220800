import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCalendarSuggestions } from '../../store/actions';
import Analytics from '../../data/Analytics';
import EventBus from '../../util/EventBus';

function AppListeners({ user, getCalendarSuggestions }) {
	useEffect(() => {
		const onAccessGiven = (data) => {
			if (data.service === 'google') getCalendarSuggestions(user.email, true);
			Analytics.event(Analytics.Event.CALENDAR_ACCESS, {
				event: 'given',
				service: data.service,
			});
		};

		EventBus.on(EventBus.Event.ACCESS_GIVEN, onAccessGiven);
		return () => EventBus.remove(EventBus.Event.ACCESS_GIVEN, onAccessGiven);
	}, []);

	return null;
}

const mapDispatchToProps = (dispatch) => ({
	getCalendarSuggestions: (...args) =>
		dispatch(getCalendarSuggestions(...args)),
});

export default connect(null, mapDispatchToProps)(AppListeners);
