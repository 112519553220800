import React from 'react';
import { ChevronRight } from 'react-feather';

export default function CollapseExpandIcon({ active, className, size }) {
	return (
		<ChevronRight
			className={`${className} ${active ? 'active' : ''} ${size}`}
		/>
	);
}
