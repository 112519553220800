import { applyMiddleware, createStore } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './reducer/index';

// BINDING MIDDLEWARE
const bindMiddleware = (middleware) => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state, // use previous state
			...action.payload, // apply delta from hydration
		};
		/**
		 * Preserve values explicitly when using client side navigation
		 */
		// nextState.VariantReducer.variant = state.VariantReducer.variant;

		return nextState;
	} else {
		return rootReducer(state, action);
	}
};

const initStore = ({ isServer }) => {
	return createStore(reducer, bindMiddleware([thunkMiddleware]));
};

// Export the wrapper & wrap the pages/_app.js with this wrapper only
export const wrapper = createWrapper(initStore);
