import { useEffect, useState } from 'react';
import { Drawer, Dropdown, Layout, Menu } from 'antd';
import Link from '../Link';
import Links, { LinkUtils } from '../../navigation/Links';
import useWindowDimensions from '../../components/hoc/useDimensions';
import HeaderScrollListener from './HeaderScrollListener';
import Avatar from '../Avatar';
import Button from '../Button';
import { useUser } from '../hoc/withUser';
import SwanvoLogo from '../../../public/images/swanvo.svg';
import SwanvoLogoWhite from '../../../public/images/swanvo-heading-logo.svg';
import CollapseExpandIcon from '../CollapseExpandIcon';
import { withRouter } from 'next/router';
import Util from '../../util';
import { logout } from '../../store/actions';
import { connect } from 'react-redux';

const links = [];

function NavigationSidebar({ visible, closeSidebar, logout }) {
	return (
		<Drawer
			placement="left"
			closable={true}
			onClose={closeSidebar}
			visible={visible}
		>
			<div>
				<Link href={Links.LANDING}>
					<SwanvoLogo className="brand-logo" />
				</Link>
				<Menu mode="vertical">
					{links.map((link, index) => (
						<Menu.Item key={index}>
							<Link href={link.link} onClick={closeSidebar}>
								{link.name}
							</Link>
						</Menu.Item>
					))}
				</Menu>
			</div>
		</Drawer>
	);
}

function Navbar({ router, logout }) {
	const { isLoggedIn, user } = useUser();
	const [sidebarOpened, toggleSidebar] = useState(false);
	const { width } = useWindowDimensions();
	const isMobile = width > 0 && width < 768;
	const [colored, setColored] = useState(Util.isColoredNavbar(router.pathname));

	useEffect(() => {
		setColored(Util.isColoredNavbar(router.pathname));
	}, [router]);

	const onLogout = !isLoggedIn ? undefined : () => logout();

	function renderUser() {
		return (
			<Dropdown
				className="profile-dropdown"
				placement="bottomRight"
				trigger={['click']}
				overlay={
					<Menu>
						<Menu.Item>
							<Link href={Links.User(user.username)}>Profile</Link>
						</Menu.Item>
						<Menu.Item danger onClick={onLogout}>
							Logout
						</Menu.Item>
					</Menu>
				}
			>
				<div className="valigned clickable">
					<Avatar src={user.image} size={36} clickable />
					<div
						className="body-small semibold heading-font"
						style={{ marginLeft: 10, marginRight: 10 }}
					>
						{user.name}
					</div>
					<CollapseExpandIcon
						size="small"
						className="collapse-arrow dropdown-arrow"
					/>
				</div>
			</Dropdown>
		);
	}

	return (
		<>
			{/*{isMobile && <NavigationSidebar visible={sidebarOpened} closeSidebar={() => toggleSidebar(false)} />}*/}
			<Layout.Header className="navbar">
				<div className="navbar-wrapper">
					<div className="navbar-container">
						<div className="navbar-left">
							{/*{isMobile && (*/}
							{/*	<MenuIcon className='sidebar-button' size={24} onClick={() => toggleSidebar(!sidebarOpened)} />*/}
							{/*)}*/}
							<div>
								<Link href={Links.LANDING}>
									{colored ? <SwanvoLogoWhite /> : <SwanvoLogo />}
								</Link>
								{user?.company && (
									<>
										{' '}
										<Link
											className="navbar-heading navbar-company-name"
											href={Links.Company(user.company)}
										>
											/ {user.company}
										</Link>
									</>
								)}
							</div>
						</div>
						<div className="navbar-center navbar-heading">
							{LinkUtils.isCompanyPage(router.pathname)
								? LinkUtils.isShoutoutPage(router.pathname) &&
								  'Company Shoutouts Board'
								: LinkUtils.isShoutoutPage(router.pathname)
								? `${
										LinkUtils.isOwnProfile(user, router) ? 'My ' : ''
								  }Shoutouts Board`
								: LinkUtils.isAchievementsPage(router.pathname) &&
								  `${
										LinkUtils.isOwnProfile(user, router) ? 'My ' : ''
								  }Achievements`}
						</div>
						<div className="navbar-right">
							{isLoggedIn
								? renderUser()
								: router.pathname !== Links.LOGIN &&
								  router.pathname !== Links.EMAIL_SHOUTOUT_LOGIN &&
								  router.pathname !== Links.CAMARADLY_SHOUTOUT_LOGIN && (
										<Button
											href={Links.LOGIN}
											type="outlined"
											size="small"
											color="primary"
										>
											Login
										</Button>
								  )}
						</div>
					</div>
				</div>
			</Layout.Header>
			<HeaderScrollListener />
		</>
	);
}

const mapDispatchToProps = (dispatch) => ({
	logout: (...args) => dispatch(logout(...args)),
});

export default connect(null, mapDispatchToProps)(withRouter(Navbar));
