import 'antd/dist/antd.min.css';
import '../assets/globals.scss';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import { swrFetcher } from '../util/http';
import { BackTop, Layout } from 'antd';
import Error from 'next/error';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import ScrollToTop from '../components/layout/ScrollToTop';
import { Provider } from 'next-auth/client';
import dynamic from 'next/dynamic';
import { ArrowUp } from 'react-feather';
import Analytics from '../data/Analytics';
import { useRouter } from 'next/router';
import { useUser } from '../components/hoc/withUser';
import { setupSentry } from '../util/sentry';
import { wrapper } from '../store/store';
import { Provider as ReduxProvider, useStore } from 'react-redux';
import GoogleService from '../services/GoogleService';
import AppListeners from '../components/app/AppListeners';

const TopProgressBar = dynamic(() => import('../components/TopProgressBar'), {
	ssr: false,
});

setupSentry();

function Content({ Component, pageProps, ...props }) {
	const { user, fetchingUser } = useUser();

	useEffect(() => {
		if (!fetchingUser || user) Analytics.setUser(user);
	}, [user, fetchingUser]);

	return (
		<Layout>
			{!fetchingUser && <AppListeners user={user} />}
			<TopProgressBar />
			<Navbar />
			<Layout.Content>
				{pageProps.error ? (
					<Error
						statusCode={pageProps.error.statusCode}
						title={pageProps.error.message}
					/>
				) : (
					<Component {...pageProps} {...props} />
				)}
				<Footer />
			</Layout.Content>
			<ScrollToTop />
			<BackTop>
				<ArrowUp />
			</BackTop>
		</Layout>
	);
}

function Swanvo({ Component, pageProps }) {
	const router = useRouter();
	const store = useStore();

	useEffect(() => {
		Analytics.init();
		GoogleService.init();

		router.events.on('routeChangeComplete', Analytics.pageview);
		return () => router.events.off('routeChangeComplete', Analytics.pageview);
	}, []);

	return (
		<SWRConfig value={{ fetcher: swrFetcher }}>
			<ReduxProvider store={store}>
				<Provider session={pageProps.session}>
					<Content Component={Component} pageProps={pageProps} />
				</Provider>
			</ReduxProvider>
		</SWRConfig>
	);
}

export default wrapper.withRedux(Swanvo);
