import Action from '../Action';

const initialState = {
	calendar: null,
	fetching: false,
};

export default (state = initialState, action = { type: '' }) => {
	switch (action.type) {
		case Action.LOGOUT:
			return { ...state, ...initialState };

		case Action.SET_CALENDAR_SUGGESTIONS:
			return { ...state, calendar: action.suggestions };
		case Action.SET_SUGGESTIONS_FETCHING:
			return { ...state, fetching: action.fetching };
		default:
			return state;
	}
};
