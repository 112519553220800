import Link from './Link';

export default function Button({
	type = 'filled',
	color = 'primary',
	size = '',
	className = '',
	href,
	...props
}) {
	return (
		<Link
			{...props}
			href={href}
			className={`button ${type} color-${color} ${
				size ? `size-${size}` : ''
			} ${className}`}
		/>
	);
}
