import { Layout } from 'antd';
import PageSection from '../PageSection';
import Facebook from '../../../public/icons/facebook.svg';
import LinkedIn from '../../../public/icons/linkedin.svg';
import Instagram from '../../../public/icons/instagram.svg';

function Footer() {
	return (
		<Layout.Footer>
			<PageSection className="footer-container">
				<div className="footer-copyrights-section">
					<div className="footer-copyrights">
						2021 CAMARADLY - All rights reserved.
					</div>
					<a
						href="mailto:hello@swanvo.com"
						rel="noopener noreferrer"
						target="_blank"
					>
						hello@swanvo.com
					</a>
					<div className="footer-social-links">
						<a
							href="https://www.facebook.com/camaradly"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Facebook />
						</a>
						<a
							href="https://www.linkedin.com/company/camaradly/"
							rel="noopener noreferrer"
							target="_blank"
						>
							<LinkedIn />
						</a>
						<a
							href="https://www.instagram.com/camaradly/?igshid=76hxygkgzn5q"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Instagram />
						</a>
					</div>
				</div>
			</PageSection>
		</Layout.Footer>
	);
}

export default Footer;
