import { useEffect } from 'react';
import { withRouter } from 'next/router';

/**
 * @return {null}
 */
function ScrollToTop({ router }) {
	useEffect(() => {
		function handleRouteChange(url, { shallow }) {
			if (!shallow) window.scrollTo(0, 0);
		}

		router.events.on('routeChangeComplete', handleRouteChange);
		return () => router.events.off('routeChangeComplete', handleRouteChange);
	}, []);

	return null;
}

export default withRouter(ScrollToTop);
