import React, { useEffect, useState } from 'react';

function getWindowDimensions() {
	if (typeof window === 'undefined') return { width: 0, height: 0 };
	const { innerWidth: width, innerHeight: height } = window;
	return { width, height };
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		handleResize();

		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

export function withWindowDimensions(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return (props) => {
		return (
			<WrappedComponent {...props} windowDimensions={useWindowDimensions()} />
		);
	};
}
